import React, { useEffect, useRef, useState } from "react";
import "./Record.less";
import VoiceMessage from "./VoiceMessage";
import { Card, ImagePreview } from "react-vant";
import { useLocation, useNavigate } from "react-router-dom";
import { getChatHistory } from "api/lesson";
import CommonNavBar from "views/components/CommonNavBar";
import useScrollLoad from "utils/useScrollLoad";
const audio = new Audio();
var src_url = "";
const loadingText = {
  nomore: "已经到底啦～",
  loading: "正在加载～",
  empty: "暂无数据"
};
function Record() {
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(useLocation().search);
  const [J_data, setJ_data] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [srcTime, setSrcTime] = useState("");
  const [srcText, setsrcText] = useState("");
  const [isPlaying, setIsPlaying] = useState(false);
  const loadRef = useRef(null);
  const [allData, setAllData] = useState([]);
  const [lastSrc, setLastSrc] = useState([]);
  const [info, setInfo] = useState([]);

  // 播放下一个音频的函数
  const playNextAudio = () => {
    try {
      if (src_url.length > 0) {
        const nextAudioSrc = src_url.shift(); // 获取并移除列表中的下一个音频
        audio.src = `${process.env.REACT_APP_API_CDN}/` + nextAudioSrc; // 设置音频源
        audio.preload = "auto"; // 自动预加载
        // 监听音频播放结束事件，在音频播放结束后继续播放下一个
        audio.onended = () => {
          if (src_url.length > 0) {
            const nextSrc = src_url.shift(); // 获取并移除列表中的下一个音频
            audio.src = `${process.env.REACT_APP_API_CDN}/` + nextSrc; // 设置音频源
            try {
              audio.play(); // 播放音频
              // eslint-disable-next-line no-empty
            } catch (i) {}
          } else {
            setSrcTime("");
            setsrcText("");
            setIsPlaying(false);
          }
        };
        audio.onpause = () => {
          setIsPlaying(false);
        };
        audio.oncanplaythrough = () => {
          setIsPlaying(true);
          try {
            audio.play();
            // eslint-disable-next-line no-empty
          } catch (i) {}
        };
        audio.play();
      }
    } catch (i) {
      // eslint-disable-next-line no-empty
    }
  };

  /**
   * @description 切换播放状态
   * @param {string} src - 音频的src
   * @param {string} time - 音频的播放时间
   * @param {string} text - 音频的文本描述
   */
  const togglePlay = (src, time, text) => {
    try {
      audio.pause(); // 暂停音频
      if (
        isPlaying &&
        lastSrc.toString() === src.toString() &&
        time === srcTime &&
        srcText === text
      ) {
        setSrcTime("");
        setsrcText("");
      } else {
        audio.src = "";
        src_url = JSON.parse(src);
        setSrcTime(time);
        setsrcText(text);
        playNextAudio(); // 播放下一个音频
        setLastSrc(src);
      }
      // eslint-disable-next-line no-empty
    } catch (i) {}
  };
  //图片预览
  const bigImg = (src) => {
    const images = [`${process.env.REACT_APP_API_CDN}` + src];
    ImagePreview.open({
      images
    });
  };
  const fetchData = async () => {
    setIsLoading(true);
    const res = await getChatHistory(searchParams.get("lesson_id"));
    setIsLoading(false);
    try {
      if (res.code === 200) {
        setAllData(res.data.data);
        setInfo(res.data.info);
        loadData(res.data.data);
      }
    } catch (e) {
      console.log(e);
    }
  };
  const loadData = (newAllData) => {
    const copyAllData = newAllData || allData;
    if (J_data.length < copyAllData.length) {
      setIsLoading(true);
      loadRef.current = setTimeout(() => {
        const nextData = copyAllData.filter(
          (_, index) => index >= J_data.length && index < J_data.length + 50
        );
        setJ_data([...J_data, ...nextData]);
        setIsLoading(false);
      }, 1000);
    }
  };
  const containerRef = useScrollLoad(() => {
    if (!isLoading) {
      loadData();
    }
  });
  useEffect(() => {
    fetchData();
    return () => {
      if (loadRef.current) {
        clearTimeout(loadRef.current);
      }
    };
  }, []);
  return (
    <div className="record_page" ref={containerRef}>
      <CommonNavBar
        title="聊天记录"
        backgroundColor={"#f5f5f5"}
        onClickBack={() => {
          navigate(-1);
        }}
      />
      {J_data.map((item, index) => {
        if (item.type === "teacher") {
          return (
            <div className="teacher_info" key={index}>
              <img alt="image" src={info.tea_avatar} />
              <div className="contont">
                <div className="left_mark"></div>
                <Card round>
                  <Card.Body>
                    <VoiceMessage
                      color={"rgb(255, 255, 255)"}
                      created_at={item.created_at}
                      message={item.message}
                      src={item.bin_data}
                      srcText={srcText}
                      srcTime={srcTime}
                      togglePlay={togglePlay}
                      type={item.type}
                    />
                  </Card.Body>
                </Card>
              </div>
            </div>
          );
        } else if (item.type === "user") {
          return (
            <div className="my_info" key={index}>
              <img
                alt="image"
                src={
                  typeof info.stu_avatar === "string"
                    ? `${process.env.REACT_APP_API_CDN}${
                        info.stu_avatar[0] === "/" ? "" : "/"
                      }${info.stu_avatar}`
                    : ""
                }
              />
              <div className="contont">
                <Card round>
                  <Card.Body>
                    <VoiceMessage
                      color={"rgb(149, 216, 105)"}
                      created_at={item.created_at}
                      message={item.message}
                      src={item.bin_data}
                      srcText={srcText}
                      srcTime={srcTime}
                      togglePlay={togglePlay}
                      type={item.type}
                    />
                  </Card.Body>
                </Card>
                <div className="left_mark"></div>
              </div>
            </div>
          );
        } else {
          return (
            <div className="teacher_info" key={index}>
              <img alt="image" src={info.tea_avatar} />
              <div className="photo_style">
                <img
                  onClick={() => {
                    bigImg(JSON.parse(item.bin_data));
                  }}
                  src={
                    `${process.env.REACT_APP_API_CDN}/` +
                    JSON.parse(item.bin_data)
                  }
                />
                <div>{item.created_at}</div>
              </div>
            </div>
          );
        }
      })}
      <div className="bottom">
        {isLoading && J_data.length == 0
          ? ""
          : isLoading
          ? loadingText["loading"]
          : J_data.length === 0
          ? loadingText["empty"]
          : loadingText["nomore"]}
      </div>
    </div>
  );
}

export default Record;
