import React from "react";
import { Image, Skeleton } from "react-vant";
import { Swiper, SwiperSlide } from "swiper/react";
import { useNavigate } from "react-router-dom";
import "./CollectCom.less";
import { useEffect } from "react";
import { getMyMaterials } from "api/course";
import { useState } from "react";
import { Photo } from "@react-vant/icons";

function CollectCom() {
  const [collectList, setCollect] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      const favoriteRes = await getMyMaterials();
      if (favoriteRes.code === 200) {
        try {
          if (favoriteRes.data.length < 2) {
            favoriteRes.data.push({
              cover: require("@/images/image/seize.png"),
              title: "更多教材待收藏"
            });
          }
          setCollect(favoriteRes.data);
        } catch (e) {
          console.log(e);
        }
      }
    };

    fetchData();
  }, []);

  return (
    <div className="collectBox">
      {collectList.length > 0 ? (
        <div className="collect-list">
          {collectList.map((item, index) => (
            <div
              className="collect-list-item"
              key={`collect-item-${index}`}
              onClick={() => {
                if (item.series_id && item.grade_id && item.book_id) {
                  navigate(
                    `/textbook/coursePackage?series_id=${item.series_id}&grade_id=${item.grade_id}&book_id=${item.book_id}`
                  );
                } else {
                  navigate("/main/textbook");
                }
              }}
            >
              <Image
                className={`item-img ${
                  item.title === "更多教材待收藏" ? "rv-image__img--normal" : ""
                }`}
                src={item.cover}
                showLoading={false}
                loadingIcon={
                  <Photo src={item.cover.replace("small", "thumb")} />
                }
              />
              <div className="item-text">{item.title}</div>
            </div>
          ))}
        </div>
      ) : (
        <Skeleton title />
      )}
      {/* <Skeleton loading={collectList.length <= 0} row={1} rowHeight="15rem">
        <Swiper duration="0ms" slidesPerView={3} spaceBetween="8">
          {collectList.map((item, index) => (
            <SwiperSlide
              key={index}
              onClick={() => {
                if (item.series_id && item.grade_id && item.book_id) {
                  navigate(
                    `/textbook/coursePackage?series_id=${item.series_id}&grade_id=${item.grade_id}&book_id=${item.book_id}`
                  );
                } else {
                  navigate("/main/textbook");
                }
              }}
            >
              <div className="series-swiper-slide">
                <Image
                  className={
                    item.title === "更多教材待收藏"
                      ? "rv-image__img--normal"
                      : ""
                  }
                  src={item.cover}
                  showLoading={false}
                  loadingIcon={
                    <Photo src={item.cover.replace("small", "thumb")} />
                  }
                />
                <div className="title">{item.title}</div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </Skeleton> */}
    </div>
  );
}

export default React.memo(CollectCom, () => true);
