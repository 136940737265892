import React from "react";
import { Image, Skeleton } from "react-vant";
import {createFromIconfontCN, Photo} from "@react-vant/icons";
import { Swiper, SwiperSlide } from "swiper/react";
import { useNavigate } from "react-router-dom";
import "./Recommended.less";
import { useEffect } from "react";
import { getRecommended } from "api/home";
import { useState } from "react";
const ic_data_show_collect = require("@/assets/icon/ic_data_show_collect.png");
function Recommended() {
  const [recommended, setRecommended] = useState([]);
  const IconFont = createFromIconfontCN({
    scriptUrl: import("@/images/iconfont.js")
  });
  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      const result = await getRecommended();
      setRecommended(result.data);
    };

    fetchData();
  }, []);

  return (
    <div className="recommended">
      <Skeleton loading={recommended.length <= 0} row={1} rowHeight="15rem">
        <Swiper duration="0ms" slidesPerView={3} spaceBetween="8">
          {recommended.map((item, index) => (
            <SwiperSlide
              key={index}
              onClick={() => navigate(`/textbook/level?id=${item.id}`)}
            >
              <div className="series-swiper-slide">
                <Image src={item.cover} showLoading={false} fit="cover" loadingIcon={<Photo src={item.cover+"-thumb"} />} />
                <div className="series-slide-text">
                  <div className="tj">{item.tags.map((i) => i + " ")}</div>
                  <div className="xl">{item.title}</div>
                  <div className="series-swiper-bottom">
                    {/* 插眼 需要调接口 */}
                    <div className="favorite">
                      <div>{`${item.favorites}+`}</div>
                      <img className="xin" src={ic_data_show_collect} />
                    </div>
                    <div className="study-count">{`${item.studyCount}+学习`}</div>
                  </div>
                  {/* <div className="inline-div">
                    <IconFont
                      color="#167F71"
                      fontSize={"1.5rem"}
                      name="icon-shoucang"
                    />
                    &nbsp; {item.favorites} / {item.studyCount} 已学习
                  </div> */}
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </Skeleton>
    </div>
  );
}

export default React.memo(Recommended, () => true);
