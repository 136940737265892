import request from "utils/request";

export async function getPromoterInfo() {
  return request.get("/profile/getPromoterInfo.php", "nocache");
}

export async function updatePromoter(data) {
  return request.post("/profile/updatePromoter.php", data, "nocache");
}

export async function checkUpdatePromoter() {
  return request.get("/profile/checkUpdatePromoter.php", "nocache");
}

export async function getLink() {
  return request.get("/admin/system/getLink.php", "nocache");
}