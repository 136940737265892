import React from "react";
import { Skeleton } from "react-vant";
import { getBanner } from "api/home";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Image } from "react-vant";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import "./Banners.less";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
function Banners() {
  const [banner, setBanner] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      const bannerData = await getBanner();
      setBanner(bannerData.data);
    };
    fetchData();
  }, []);
  return (
    <div className="banners-content">
      <Skeleton loading={banner.length <= 0} row={1} rowHeight="13.5rem">
        <Swiper
          autoplay={{
            delay: 5000,
            stopOnLastSlide: false,
            disableOnInteraction: false
          }}
          loop
          modules={[Pagination, Autoplay]}
          pagination={{ clickable: true }}
          slidesPerView={1}
          spaceBetween={10}
        >
          {banner.length > 0 ? (
            banner.map((result) => (
              <SwiperSlide key={result.banner_id}>
                <Image
                  fit="cover"
                  lazyload
                  onClick={() => {
                    if (result.link_url) {
                      navigate(result.link_url);
                    }
                  }}
                  src={result.image_url}
                />
              </SwiperSlide>
            ))
          ) : (
            <>
              <SwiperSlide>
                <Image
                  cover
                  lazyload
                  src={require(`../../../images/image/banner1.png`)}
                />
              </SwiperSlide>
            </>
          )}
        </Swiper>
      </Skeleton>
    </div>
  );
}

export default React.memo(Banners, () => true);
