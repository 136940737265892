import { useEffect, useState } from "react";
import { Skeleton } from "react-vant";
import { useNavigate } from "react-router-dom";
import "./MyProduct.less";
import { myProductList } from "api/product";
import CommonNavBar from "views/components/CommonNavBar";
const ic_landing_warn = require("@/assets/icon/ic_landing_warn.png");
function MyProduct() {
  const navigate = useNavigate();
  const [product, setProduct] = useState([]);
  const [flag, setFlag] = useState(false);
  const btnFn = (item) =>
    navigate(`/mall/productDetail`, {
      state: item
    });
  useEffect(() => {
    const getData = async () => {
      const result = await myProductList();
      setProduct(result.data);
      setFlag(true);
    };
    getData();
  }, []);
  return (
    <div className="my-product">
      <CommonNavBar
        title="我的订单"
        backgroundColor={"transparent"}
        onClickBack={() => {
          navigate(-1);
        }}
      />
      <div className="my-product-context">
        <Skeleton loading={flag ? false : true} row={4}>
          <div className="productList">
            {product.map((item) => {
              const [prePrice, unitPrice] = item.total_amount.split(".");
              const [YYYY, MM, DD] = item.order_date.split("-");
              return (
                <div className="productItemBox" key={item.order_no}>
                  <div className="top">
                    <img
                      src={`${process.env.REACT_APP_API_CDN}${item.thumb}`}
                      alt=""
                    />
                    <div className="top_right">
                      <div className="top_right_title">{item.product_name}</div>
                      <div className="top_right_orderCode">
                        {item.order_status == "已支付"
                          ? `订单编号：${item.order_no}`
                          : `创建时间：${YYYY}年${MM}月${DD}日`}
                      </div>
                      <div className="top_right_count">
                        数量：{item.quantity}
                      </div>
                    </div>
                  </div>
                  {/* {item.order_status == "已支付" ? null : (
                    <div className="orderClose">
                      <img src={ic_landing_warn} alt="" /> 1小时50分钟后订单关闭
                    </div>
                  )} */}
                  <div
                    className={`bottom ${
                      item.order_status == "已支付" ? "" : "dzf"
                    }`}
                  >
                    <div className="msg">
                      {item.order_status}￥
                      <span className="prePrice">{prePrice}</span>.
                      <span className={`unitPrice`}>{unitPrice}</span>
                    </div>
                    <div className="btn-wrapper">
                      <div
                        className="btn"
                        onClick={() => {
                          navigate(
                            `/webUrlView?webUrl=${encodeURIComponent(
                              `${process.env.REACT_APP_API_CDN}/utalkrc/privacy/en/app-service.html`
                            )}&title=${encodeURIComponent("课程服务协议")}`
                          );
                        }}
                      >
                        查看协议
                      </div>
                      <div className="btn" onClick={() => btnFn(item)}>
                        {item.order_status == "已支付" ? "查看订单" : "去支付"}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </Skeleton>
      </div>
    </div>
  );
}

export default MyProduct;
