import { Button, Checkbox, Toast } from "react-vant";
import { useNavigate } from "react-router-dom";
import CommonNavBar from "views/components/CommonNavBar";
import styles from "./Logoff.module.less";
import { useState } from "react";
import { deleteUserController } from "api/profile";
export default function Logoff() {
  const navigate = useNavigate();
  const [canClick, setCanClick] = useState(false);
  async function handleClickOff() {
    const res = await deleteUserController();
    if (res.code === 200) {
      Toast.success("注销成功，正在返回登录页面");
      localStorage.clear();
      navigate("/login/logon", { replace: true });
    } else {
      Toast.fail(res.message || "注销失败，请重试");
    }
  }

  return (
    <div className={styles["logoff-container"]}>
      <CommonNavBar
        backgroundColor="#fff"
        onClickBack={() => {
          navigate(-1);
        }}
        title="注销账号"
      />
      <img
        className={styles["risk-img"]}
        src={require("@/images/image/risk.png")}
      />
      <h3 className={styles["risk-title"]}>注销账号的风险</h3>
      <div className={styles["des-wrapper"]}>
        <h4>无法登录，关系解绑</h4>
        <p>您一旦将账号注销，将无法继续使用该账号登录我们的任何服务平台。</p>
        <h4>信息抹除，无法恢复</h4>
        <p>
          您注销账号后将永久抹除我们任何平台的全部个人信息，包括但不限于积分、个人资料、课时
          等 。
        </p>
        <Button
          block
          className={styles["off-button"]}
          disabled={!canClick}
          onClick={handleClickOff}
          round
          type="danger"
        >
          申请注销
        </Button>
        <Checkbox
          checked={canClick}
          checkedColor="#f44336"
          className={styles["off-check"]}
          onChange={setCanClick}
        >
          我已清楚上述风险，确定继续申请注销账号
        </Checkbox>
      </div>
    </div>
  );
}
