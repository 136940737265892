import styles from "./Welcome.module.less";
import { ArrowLeft } from "@react-vant/icons";
import { Image, Button, Loading } from "react-vant";
import { useEffect, useRef, useState } from "react";
import { getDemoLesson } from "api/welcome";
import { useNavigate } from "react-router-dom";
import SetName from "./SetName";
import { handleOpenClass } from "./SetName";

function Welcome() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [selectLevel, setSelectLevel] = useState("");
  const [selectBook, setSelectBook] = useState("");
  const [page, setPage] = useState(0);
  const [visible, setVisible] = useState(false);
  const [levelList, setLevelList] = useState({});
  const videoRef = useRef(null);
  useEffect(() => {
    videoRef.current.addEventListener("loadedmetadata", () => {
      if (videoRef.current) {
        videoRef.current.controls = true;
      }
    });
    videoRef.current.load();
    getDemoLesson()
      .then((res) => {
        if (res.code === 200 && res.data.list && res.data.teacher) {
          setLevelList(res.data.list);
          localStorage.setItem(
            "teacher_info",
            JSON.stringify({
              character_id: res.data.teacher.character_id,
              image_url: res.data.teacher.image_url,
              name: res.data.teacher.name
            })
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);
  return (
    <div className={styles["welcome-container"]}>
      <div
        className={`${styles["schedule-container"]} ${
          page > 1 ? styles["schedule-container--deep"] : ""
        }`}
      >
        {[0, 1, 2, 3].map((item, index) => (
          <div
            className={`${styles["item"]} ${
              item === page ? styles["item--selected"] : ""
            }`}
            key={`schedule-${index}`}
          ></div>
        ))}
      </div>
      <div className={styles["welcome-list"]}>
        <div
          className={styles["page-0"]}
          style={{ transform: `translateX(-${page * 100}%)` }}
        >
          <div
            className={styles["page-0-skip"]}
            onClick={() => {
              // videoRef.current.pause();
              // setPage(1);
              localStorage.removeItem("teacher_info");
              navigate("/main/home", { replace: true });
            }}
          >
            跳过
          </div>
          <video
            playsInline={true}
            className={styles["page-0-video"]}
            controls
            ref={videoRef}
            src={`${process.env.REACT_APP_API_CDN}/release/1717381468523-%E8%AF%BE%E7%A8%8B%E6%8C%87%E5%8D%97.mp4`}
          ></video>
          <div className={styles["page-0-text--cn"]}>点击查看功能简介!</div>
          <div className={styles["page-0-text--en"]}>
            Click to view the function introduction
          </div>
          {loading ? (
            <Loading
              className={styles["page-0-loading"]}
              color="#fff"
              size="30px"
              type="spinner"
            />
          ) : null}
          {Object.keys(levelList).length > 0 ? (
            <div
              className={styles["page-0-next"]}
              onClick={() => {
                videoRef.current.pause();
                setPage(1);
              }}
            ></div>
          ) : null}
        </div>
        <div
          className={styles["page-1"]}
          style={{ transform: `translateX(-${page * 100}%)` }}
        >
          <div
            className={styles["page-1-skip"]}
            onClick={() => {
              localStorage.removeItem("teacher_info");
              navigate("/main/home", { replace: true });
            }}
          >
            稍后体验
          </div>
          <img
            className={styles["page-1-center"]}
            src={require("@/images/image/welcome-center.png")}
          />
          <div className={styles["page-1-text--cn"]}>开始你的体验课吧!</div>
          <div className={styles["page-1-text--en"]}>
            Start your experience class!
          </div>
          <div
            className={styles["page-1-next"]}
            onClick={() => setPage(2)}
          ></div>
        </div>
        <div
          className={styles["page-2"]}
          style={{ transform: `translateX(-${page * 100}%)` }}
        >
          <div className={styles["top"]}>
            <div
              className={styles["top-back"]}
              onClick={() => {
                setPage(1);
                setSelectLevel("");
              }}
            >
              <ArrowLeft color="rgba(42, 129, 227, 1)" />
            </div>
            <div className={styles["top-text"]}>选择级别</div>
          </div>
          <div className={styles["text-style-1"]}>Select level</div>
          <div className={styles["text-style-2"]}>
            符合以下任意条件即可选择对应级别
          </div>
          <div className={styles["level-list"]}>
            {Object.keys(levelList).map((item, i) => (
              <div
                className={`${styles["level-list-item"]} ${
                  selectLevel === item
                    ? styles["level-list-item--selected"]
                    : ""
                }`}
                key={`welcome-level-${i}`}
                onClick={() => {
                  setSelectLevel(item);
                  setTimeout(() => {
                    setPage(3);
                  }, 1000);
                }}
              >
                <div className={styles["left"]}>{item}</div>
                <div className={styles["right"]}>
                  {levelList[item].title && Array.isArray(levelList[item].title)
                    ? levelList[item].title.map((title, j) => (
                        <div
                          className={styles["right-text"]}
                          key={`welcome-title-${j}`}
                        >
                          {title}
                        </div>
                      ))
                    : null}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div
          className={styles["page-3"]}
          style={{ transform: `translateX(-${page * 100}%)` }}
        >
          <div className={styles["top"]}>
            <div
              className={styles["top-back"]}
              onClick={() => {
                setSelectLevel("");
                setSelectBook("");
                setPage(2);
              }}
            >
              <ArrowLeft color="rgba(42, 129, 227, 1)" />
            </div>
            <div className={styles["top-text"]}>选择教材</div>
          </div>
          <div className={styles["text-style-1"]}>Hello,</div>
          <div className={styles["text-style-2"]}>Please select a textbook</div>
          <div className={styles["book-list"]}>
            {selectLevel
              ? Object.keys(levelList[selectLevel].childs).map(
                  (item, index) => (
                    <div
                      className={`${styles["book-list-item"]} ${
                        selectBook === item
                          ? styles["book-list-item--selected"]
                          : ""
                      }`}
                      key={`welcome-book-${index}`}
                      onClick={() => {
                        setSelectBook(item);
                      }}
                    >
                      <Image
                        className={styles["left"]}
                        fit="cover"
                        src={levelList[selectLevel].childs[item].cover}
                      />
                      <div className={styles["right"]}>
                        <div className={styles["right-top"]}>
                          {levelList[selectLevel].childs[item].grade}
                        </div>
                        <div className={styles["right-center"]}>
                          {levelList[selectLevel].childs[item].title}
                        </div>
                        <div className={styles["right-bottom"]}>
                          {`${levelList[selectLevel].childs[item].count}`}
                        </div>
                      </div>
                    </div>
                  )
                )
              : null}
          </div>
          <Button
            className={styles["next-step"]}
            disabled={selectBook === "" || loading}
            onClick={() => {
              let userInfo = {};
              try {
                userInfo = JSON.parse(localStorage.getItem("userInfo"));
              } catch (e) {
                console.log("用户信息解析失败", e);
              }
              if (userInfo?.english_name) {
                handleOpenClass(
                  levelList[selectLevel].childs[selectBook].title,
                  levelList[selectLevel].childs[selectBook].data_json_path,
                  navigate
                );
              } else {
                setVisible(true);
              }
            }}
            type="primary"
          >
            下一步
          </Button>
        </div>
      </div>
      {selectBook ? (
        <SetName
          data_json_path={
            levelList[selectLevel].childs[selectBook].data_json_path
          }
          navigate={navigate}
          setVisible={setVisible}
          title={levelList[selectLevel].childs[selectBook].title}
          visible={visible}
        />
      ) : null}
    </div>
  );
}
export default Welcome;
