import { Button, Popup, Notify, Toast } from "react-vant";
import styles from "./Welcome.module.less";
import { useRef, useState } from "react";
import { updateUserName } from "api/welcome";
import { getMyQualification } from "api/lesson";
import { getStudentInfo, getTeacherInfo } from "utils/userProfile";
import { calculateAge } from "utils/date";
import DSBridge from "dsbridge";
import { getUserProfile } from "api/profile";

export async function handleOpenClass(title, data_json_path, navigate) {
  Toast.loading({
    message: "正在进入教室...",
    forbidClick: true,
    duration: 0
  });
  await getUserProfile();
  const { data, message } = await getMyQualification({
    material_id:
      data_json_path ??
      localStorage.getItem("data_json_path") ??
      "test1/materials/test1_lesson1_1120.json",
    character_id: getTeacherInfo().character_id
  });
  Toast.clear();
  if (data && typeof data === "object" && Object.keys(data).length > 0) {
    const kcUrl = `${process.env.REACT_APP_API_HTTP}://${
      process.env.REACT_APP_API_WEBSOCKET
    }?tts=elevenlabs&student_name=${
      getStudentInfo().english_name || "Emily"
    }&material=${data.material_id}&character_id=${
      getTeacherInfo().character_id
    }&llm_model=gpt-4`;
    const args = {
      url:
        kcUrl +
        `&lesson_id=${data.lesson_id}` +
        `&token=${data.token}` +
        `&age=${calculateAge(getStudentInfo().birthday)}` +
        `&mobile=${getStudentInfo().phone_number}` +
        `&member_id=${getStudentInfo().member_id}`,
      lesson_id: data.lesson_id,
      language: "remote",
      age: calculateAge(getStudentInfo().birthday),
      teaAvatar: getTeacherInfo().image_url,
      teaName: getTeacherInfo().name,
      stuAvatar: getStudentInfo().profile_image
        ? `${process.env.REACT_APP_API_CDN}` + getStudentInfo().profile_image
        : "",
      stuName: getStudentInfo().english_name || "Emily",
      coursewareTitle: title ?? localStorage.getItem("courseware_name"),
      debugInfo: "false"
    };
    console.log(args, "发送的args");
    DSBridge.call("startLesson", args, (value) => {
      console.log(value, "返回的value");
    });
    let userInfo = {};
    try {
      userInfo = JSON.parse(localStorage.getItem("userInfo"));
    } catch {
      console.log("用户信息解析失败");
    }
    if (typeof userInfo?.total_login_count === "number") {
      localStorage.setItem(
        "userInfo",
        JSON.stringify(
          Object.assign(userInfo, {
            total_login_count: userInfo.total_login_count + 1
          })
        )
      );
    }
    localStorage.removeItem("teacher_info");
    localStorage.removeItem("courseware_name");
    localStorage.removeItem("material_id");
    localStorage.removeItem("data_json_path");
    navigate("/main/mine", { replace: true });
  } else {
    Toast.info({
      message: `${message}, 5秒后将回到首页`,
      duration: 4000
    });
    setTimeout(() => {
      localStorage.removeItem("teacher_info");
      localStorage.removeItem("courseware_name");
      localStorage.removeItem("material_id");
      localStorage.removeItem("data_json_path");
      navigate("/main/home", { replace: true });
    }, 5000);
  }
}
function SetName({ title, data_json_path, setVisible, visible, navigate }) {
  const [english_name, setEnglish_name] = useState("");
  const t1 = useRef(null);
  function handleClickConfirm() {
    if (english_name !== "" && /^[a-zA-Z\s]*$/.test(english_name)) {
      t1.current = Toast.loading({
        message: "进入课程中...",
        forbidClick: true,
        duration: 0
      });
      updateUserName({
        english_name
      })
        .then(async (res) => {
          if (res.code === 200) {
            let userInfo = {};
            try {
              userInfo = JSON.parse(localStorage.getItem("userInfo"));
            } catch {
              console.log("用户信息解析失败");
            }
            localStorage.setItem(
              "userInfo",
              JSON.stringify(
                Object.assign(userInfo, {
                  english_name
                })
              )
            );
            handleOpenClass(title, data_json_path, navigate);
          }
        })
        .finally(() => {
          t1.current.clear();
        });
    } else {
      Notify.show({
        type: "danger",
        message: "英文名仅允许输入英文字符"
      });
    }
  }
  return (
    <Popup onClose={() => setVisible(false)} visible={visible}>
      <div className={styles["pop-container"]}>
        <img
          className={styles["pop-avatar"]}
          src={require("@/images/image/Ub.png")}
        />
        <h2 className={styles["pop-title"]}>还不知道你的名字呢！</h2>
        <div className={styles["pop-text"]}>填写你的英文名吧~</div>
        <input
          className={styles["pop-input"]}
          onChange={(t) => {
            setEnglish_name(t.target.value);
          }}
          type="text"
        />
        <Button
          className={styles["pop-button"]}
          disabled={english_name === ""}
          onClick={handleClickConfirm}
          type="primary"
        >
          进入课程
        </Button>
        <img
          className={styles["pop-img--1"]}
          src={require("@/images/collect/img-1.png")}
        />
        <img
          className={styles["pop-img--2"]}
          src={require("@/images/collect/img-2.png")}
        />
        <img
          className={styles["pop-img--3"]}
          src={require("@/images/collect/img-3.png")}
        />
        <img
          className={styles["pop-img--4"]}
          src={require("@/images/collect/img-4.png")}
        />
        <img
          className={styles["pop-img--5"]}
          src={require("@/images/collect/img-5.png")}
        />
        <img
          className={styles["pop-img--6"]}
          src={require("@/images/collect/img-6.png")}
        />
        <img
          className={styles["pop-img--7"]}
          src={require("@/images/collect/img-7.png")}
        />
        <img
          className={styles["pop-img--8"]}
          src={require("@/images/collect/img-8.png")}
        />
        <img
          className={styles["pop-img--9"]}
          src={require("@/images/collect/img-9.png")}
        />
      </div>
    </Popup>
  );
}

export default SetName;
