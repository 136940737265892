import "./index.less";
import { useNavigate } from "react-router-dom";
const bg = require("@/assets/images/invite/背景.jpg");
const title1 = require("@/assets/images/invite/标题-8.png");
const jb = require("@/assets/images/invite/金币-8.png");
const q100 = require("@/assets/images/invite/u宝-8.png");
const zcfk = require("@/assets/images/invite/注册方框-8.png");
const btnImg = require("@/assets/images/invite/立即体验按钮-8.png");
const tsImg = require("@/assets/images/invite/课程特色方框-8.png");
const kcImg = require("@/assets/images/invite/课程内容方框-8.png");
const xxub = require("@/assets/images/invite/学习U宝-8.png");
const tbpt = require("@/assets/images/invite/同步配套课件-8.png");
const dsgp = require("@/assets/images/invite/短时高频学习-8.png");
const pjkc = require("@/assets/images/invite/评价的课程-8.png");
const qmdb = require("@/assets/images/invite/全面对标考纲-8.png");
const zrwj = require("@/assets/images/invite/真人外教复刻-8.png");
const xgn = require("@/assets/images/invite/新概念配套口语练习-8.png");
const pep = require("@/assets/images/invite/PEP人教版口语练习-8.png");
const goForIt = require("@/assets/images/invite/goForIt.png");
const qgzt = require("@/assets/images/invite/情景主题自由会话-8.png");
const cc = require("@/assets/images/invite/草丛-8.png");
const code = require("@/assets/images/invite/二维码-8.png");
import { Checkbox, Input, Form, Toast, Button } from "react-vant";
import CodeDown from "@/views/login/components/CodeDown";
import getRules from "@/utils/validators";
import { register } from "@/api/register";
import { getUrlParams } from "utils/tools";

export default () => {
  const [form] = Form.useForm();
  const { getFieldsValue } = form;
  const navigate = useNavigate();
  const commit = async () => {
    // phone_number: undefined,
    // verification_code: undefined,
    // password: undefined,
    // repassword: undefined,
    // inviteCode: undefined
    // const defaultPassword = 123456;
    const params = getFieldsValue();
    Object.assign(params, {
      // Password: defaultPassword,
      // repassword: defaultPassword,
      // 邀请码
      rec_id: getUrlParams().rec_id,
      is_web: 1
    });
    delete params.checkbox;
    const res = await register(params);
    Toast.info(res?.message ?? "未知错误");
    if (res.code == 200) navigate("/joinSuccess");
  };
  return (
    <div className="InviteBox">
      <img className="bg" src={bg} />
      <img className="title1" src={title1}></img>
      <img className="jb" src={jb}></img>
      <img className="q100" src={q100}></img>
      {/* 第一个框 */}
      <img className="zcfk" src={zcfk}></img>
      <div className="userInfoBox">
        <Form form={form} onFinish={commit}>
          <div className="formItem">
            <Form.Item
              rules={[{ required: false, message: "请输入英文名" }]}
              name="name"
            >
              <Input placeholder="英文名" />
            </Form.Item>
          </div>
          <div className="formItem">
            <Form.Item
              rules={[
                { required: true, message: "请输入手机号" },
                {
                  validator: (_, value) => {
                    if (getRules().phone.test(value))
                      return Promise.resolve(true);
                    return Promise.reject(new Error("请输入正确的手机号码"));
                  }
                }
              ]}
              name="phone_number"
            >
              <Input placeholder="手机号" />
            </Form.Item>
          </div>
          <div className="formItem">
            <Form.Item
              rules={[{ required: true, message: "请输入验证码" }]}
              name="verification_code"
            >
              <Input placeholder="验证码" />
            </Form.Item>
            <a className="tagA a">
              <CodeDown getPhone={() => getFieldsValue().phone_number} />
            </a>
          </div>
          <div className="formItem">
            <Form.Item
              name="checkbox"
              rules={[{ required: true, message: "请勾选协议" }]}
            >
              <Checkbox shape="square" />
            </Form.Item>
            <span className="xy">
              同意
              <a
                className="tagA"
                onClick={() => {
                  navigate(
                    `/webUrlView?webUrl=${encodeURIComponent(
                      `${process.env.REACT_APP_API_CDN}/utalkrc/privacy/en/app-service.html`
                    )}&title=${encodeURIComponent("课程服务协议")}`
                  );
                }}
              >
                《课程服务协议》
              </a>
              与
              <a
                className="tagA"
                onClick={() => {
                  navigate(
                    `/mine/webUrl?webUrl=${encodeURIComponent(
                      `${process.env.REACT_APP_API_CDN}/utalkrc/privacy/en/app-privacy-policy.html`
                    )}&title=${encodeURIComponent("隐私协议")}`
                  );
                }}
              >
                《隐私政策》
              </a>
            </span>
          </div>
          <div className="formItem a">
            <Button nativeType="submit">
              <img src={btnImg} />
            </Button>
          </div>
        </Form>
      </div>
      {/* 第二个框 */}
      <img className="tsImg" src={tsImg}></img>
      <img className="xxub" src={xxub}></img>
      <img className="zrwj" src={zrwj}></img>
      <img className="qmdb" src={qmdb}></img>
      <img className="tbpt" src={tbpt}></img>
      <img className="dsgp" src={dsgp}></img>
      <img className="pjkc" src={pjkc}></img>
      {/* 第三个框 */}
      <img className="kcImg" src={kcImg}></img>
      <img className="xgn" src={xgn}></img>
      <img className="pep" src={pep}></img>
      <img className="goForIt" src={goForIt}></img>
      <img className="qgzt" src={qgzt}></img>
      {/* 底部 */}
      <img className="code" src={code}></img>
      <img className="cc" src={cc}></img>
    </div>
  );
};
