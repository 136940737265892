import request from "utils/request";

export async function getMaterialIndex(currentPage) {
  return request.get(`/materials/getMaterialIndex.php?currentPage=${currentPage}&pageSize=20`, "nocache");
}

export async function getMaterialBook(id) {
  return request.get(`/materials/getMaterialBook.php?series_id=${id}`, "nocache");
}

export async function getMaterialGrade(params) {
  return request.get(`/materials/getMaterialGrade.php?series_id=${params.series_id}&grade_id=${params.grade_id}&book_id=${params.book_id}`, "nocache");
}