import { useNavigate } from "react-router-dom";
import CommonNavBar from "views/components/CommonNavBar";
import styles from "./Textbook.module.less";
import { getMaterialIndex } from "api/textbook";
import { useEffect, useState } from "react";
import { Skeleton } from "react-vant";
import useScrollLoad from "utils/useScrollLoad";
const loadingText = {
  nomore: "已经到底啦～",
  loading: "正在加载～",
  empty: "暂无数据"
};
const Textbook = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [topData, setTopData] = useState(null);
  const [centerData, setCenterData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const loadData = async () => {
    setIsLoading(true);
    const res = await getMaterialIndex(currentPage);
    setIsLoading(false);
    if (res.code === 200) {
      if (res.data.top.hasOwnProperty("id")) {
        setTopData(res.data?.top);
        res.data.total_count -= 1;
      }
      setTotalCount(res.data.total_count);
      setCurrentPage(currentPage + 1);
      if (Array.isArray(res.data?.data) && res.data?.data.length > 0) {
        setCenterData([...centerData, ...res.data.data]);
      } else {
        setTotalCount(0);
      }
    }
  };
  const containerRef = useScrollLoad(() => {
    if (centerData.length < totalCount && !isLoading) {
      loadData();
    }
  });
  useEffect(() => {
    loadData();
  }, []);
  return (
    <div className={styles["textbook-container"]} ref={containerRef}>
      <CommonNavBar
        showLeft={false}
        title={"教材系列"}
        backgroundColor={"#fff"}
      />
      {isLoading && centerData.length === 0 ? <Skeleton title /> : null}
      {topData ? (
        <div
          className={styles["top-data"]}
          onClick={() => {
            navigate(`/textbook/level?id=${topData.id}`);
          }}
        >
          <img className={styles["top-data-pic"]} src={topData?.cover} />
          <div className={styles["top-data-text"]}>{topData?.title || ""}</div>
        </div>
      ) : null}
      {centerData.length > 0 ? (
        <div className={styles["vertical"]}>
          {centerData.map((item, index) => (
            <div
              className={styles["vertical-item"]}
              key={`textbook-vertical-item-${index}`}
              onClick={() => {
                navigate(`/textbook/level?id=${item.id}`);
              }}
            >
              <img className={styles["vertical-item-pic"]} src={item.cover} />
              <div className={styles["vertical-item-text"]}>{item.title}</div>
            </div>
          ))}
        </div>
      ) : null}
      <div className={styles["bottom"]}>
        {isLoading && centerData.length === 0
          ? ""
          : isLoading
          ? loadingText["loading"]
          : centerData.length === 0
          ? loadingText["empty"]
          : loadingText["nomore"]}
      </div>
    </div>
  );
};

export default Textbook;
