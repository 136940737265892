import React, { useState, useRef, useEffect } from "react";
import DSBridge from "dsbridge";
import { Toast } from "react-vant";
import { getMyQualification, getPreLesson } from "api/lesson";
import { calculateAge } from "utils/date";
import { useNavigate } from "react-router-dom";
import { getStudentInfo, getTeacherInfo } from "utils/userProfile";
import CommonModal from "views/components/CommonModal";
import styles from "./TransferPage.module.less";
import SetName from "views/welcome/SetName";
import { getUserProfile } from "api/profile";

function TransferPage({ visible, setVisible }) {
  const navigate = useNavigate();
  const loadingRef = useRef(null);
  const [initResult, setInitResult] = useState(null);
  const [mes, updateMes] = useState([]);
  const [isFirst, setIsFirst] = useState(false);
  const teacherInfo = getTeacherInfo();
  const _ = require("lodash");

  const character_id = teacherInfo.character_id;
  function close() {
    setVisible(false);
    setInitResult(null);
    localStorage.removeItem("teacher_info");
    localStorage.removeItem("courseware_name");
    localStorage.removeItem("material_id");
    localStorage.removeItem("data_json_path");
  }
  const Qualification = _.debounce(async () => {
    loadingRef.current = Toast.loading({
      message: "正在进入教室...",
      forbidClick: true,
      duration: 0
    });
    await getUserProfile();
    const { data, message } = await getMyQualification(
      {
        material_id: localStorage.getItem("data_json_path"),
        character_id
      },
      {
        toast: "hideToast"
      }
    );
    loadingRef.current.clear();
    const studentInfo = getStudentInfo();
    const age = studentInfo.birthday;
    const phone_number = studentInfo.phone_number;
    const member_id = studentInfo.member_id;
    if (data && typeof data === "object" && Object.keys(data).length > 0) {
      const language = localStorage.getItem("language") || "remote";
      const args = {
        url:
          `${process.env.REACT_APP_API_HTTP}://${
            process.env.REACT_APP_API_WEBSOCKET
          }?tts=elevenlabs&student_name=${
            studentInfo.english_name || "Emily"
          }&material=${
            data.material_id
          }&character_id=${character_id}&llm_model=gpt-4` +
          `&lesson_id=${data.lesson_id}` +
          `&token=${data.token}` +
          `&age=${calculateAge(age)}` +
          `&mobile=${phone_number}` +
          `&member_id=${member_id}`,
        lesson_id: data.lesson_id,
        language: language,
        age: calculateAge(age),
        teaAvatar: teacherInfo.image_url,
        teaName: teacherInfo.name,
        stuAvatar:
          `${process.env.REACT_APP_API_CDN}` +
          `${studentInfo.profile_image[0] === "/" ? "" : "/"}` +
          studentInfo.profile_image,
        stuName: studentInfo.english_name || "Emily",
        coursewareTitle: localStorage.getItem("courseware_name"),
        debugInfo: "false"
      };
      console.log(args, "发送的args");
      updateMes([...mes]);
      DSBridge.call("startLesson", args, (value) => {
        console.log(value, "返回的value");
      });
      close();
      navigate("/main/mine");
    } else {
      Toast.info({
        message: message || "请确认返回重试！",
        duration: 4000
      });
    }
  }, 250);

  const initData = async () => {
    const material_id = localStorage.getItem("material_id") || "";
    const material_path = localStorage.getItem("data_json_path") || "";
    const postForm = new FormData();
    postForm.append("material_id", material_id);
    postForm.append("material_path", material_path);
    loadingRef.current = Toast.loading({
      message: "正在获取课程信息...",
      forbidClick: true,
      duration: 0
    });
    const res = await getPreLesson(postForm);
    loadingRef.current.clear();
    if (res.code === 200) {
      if (res.data?.msg && res.data?.code) {
        if (res.data.code === 202) {
          Qualification();
        } else {
          setInitResult(res.data);
        }
      } else {
        Toast.info({
          message: "获取课程信息错误，请重试...",
          duration: 4000
        });
      }
    }
  };
  const getPopResult = () => {
    if (!initResult) return;
    if (initResult.code === 201) {
      return (
        <div className={styles["success-pop-wrapper"]}>
          <div className={styles["success-pop-wrapper-title"]}>温馨提示</div>
          <div className={styles["success-pop-wrapper-text"]}>
            <div>您已学习过本课了</div>
            <div>重复学习将再次消耗课时</div>
            <div>请确认是否需要重复学习</div>
          </div>
          <div className={styles["success-pop-wrapper-button"]}>
            <div
              className={`${styles["button-item"]} ${styles["button-cancel"]}`}
              onClick={() => {
                setVisible(false);
                setInitResult(null);
              }}
            >
              再看看
            </div>
            <div
              className={`${styles["button-item"]} ${styles["button-confirm"]}`}
              onClick={Qualification}
            >
              仍要上课
            </div>
          </div>
        </div>
      );
    }
    if (initResult.code === 404) {
      return (
        <div className={styles["fail-pop-wrapper"]}>
          <div className={styles["fail-pop-wrapper-title"]}>温馨提示</div>
          <div className={styles["fail-pop-wrapper-text"]}>
            <div>哎呀！您的课时数用完啦！</div>
            <div>请及时补充课时哦～</div>
          </div>
          <div
            className={styles["fail-pop-wrapper-confirm"]}
            onClick={() => {
              setVisible(false);
              setInitResult(null);
            }}
          >
            确认
          </div>
        </div>
      );
    }
    return (
      <div className={styles["success-pop-wrapper"]}>
        <div className={styles["success-pop-wrapper-title"]}>进入课程</div>
        <div className={styles["success-pop-wrapper-text"]}>
          <div>
            <span>进入课程将</span>
            <span className={styles["success-pop-wrapper-text__color1"]}>
              消耗1课时！
            </span>
          </div>
          <div>中途退出不再重复扣课时</div>
        </div>
        <div className={styles["success-pop-wrapper-button"]}>
          <div
            className={`${styles["button-item"]} ${styles["button-cancel"]}`}
            onClick={() => {
              setVisible(false);
              setInitResult(null);
            }}
          >
            返回再看看
          </div>
          <div
            className={`${styles["button-item"]} ${styles["button-confirm"]}`}
            onClick={Qualification}
          >
            进入课程
          </div>
        </div>
      </div>
    );
  };
  useEffect(() => {
    if (visible) {
      let userInfo = {};
      try {
        userInfo = JSON.parse(localStorage.getItem("userInfo"));
      } catch {
        console.log("用户信息解析失败");
      }
      if (!userInfo?.english_name) {
        setIsFirst(true);
      }
      initData();
    }
  }, [visible]);
  return (
    <>
      {initResult ? (
        isFirst && initResult.code !== 404 ? (
          <SetName
            navigate={navigate}
            setVisible={setVisible}
            visible={visible}
          />
        ) : (
          <CommonModal isOpen={visible}>
            <div className={styles["pop-container"]}>
              {getPopResult()}
              <img
                onClick={() => {
                  setVisible(false);
                  setInitResult(null);
                }}
                className={styles["modal-close"]}
                src={require("@/images/mine/recommend-officer/button_cancel.png")}
              />
            </div>
          </CommonModal>
        )
      ) : null}
    </>
  );
}
export default TransferPage;
